import React from "react";
import styles from "./LandingBenefits.module.scss";
import Assets from "../../../../layout/assets";

export default function LandingBenefits(){
    return(
        <section className={styles.LandingBenefitsSection}>
            <div className="container">
                <h2>Help ensure every child in Indore has access to early education</h2>
                <p>Join the kinderbridge pilot program today,and take charge of your future as an edupreneur.</p>
                <div className={styles.cardWrap}>
                    <div className={styles.cards}>
                        <div className={styles.contentWrap}>
                            <figure>
                                <img src={Assets.cardImage1} alt="card-img" />
                            </figure>
                            <h3>Achieve Financial Independence</h3>
                            <p>Discover how easy and fulfilling starting your own childcare education business can be as you work towards financial independence.</p>
                        </div>
                    </div>
                    <div className={styles.cards}>
                        <div className={styles.contentWrap}>
                            <figure>
                                <img src={Assets.cardImage2} alt="card-img" />
                            </figure>
                            <h3>A Blueprint for Success</h3>
                            <p>Access everything you need to kickstart your business in early childcare education, from essential processes and technology to marketing.</p>
                        </div>
                    </div>
                    <div className={styles.cards}>
                        <div className={styles.contentWrap}>
                            <figure>
                                <img src={Assets.cardImage3} alt="card-img" />
                            </figure>
                            <h3>Ongoing Professional Growth </h3>
                            <p>Prioritize your professional development with all the support and tools needed to advance your skills and build a profitable business.</p>
                        </div>
                    </div>
                    <div className={styles.cards}>
                        <div className={styles.contentWrap}>
                            <figure>
                                <img src={Assets.cardImage4} alt="card-img" />
                            </figure>
                            <h3>Make an Impact</h3>
                            <p>Make a difference in your local community by providing the next generation of big thinkers with access to high-quality education.</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src={Assets.leftbg} alt="" className={styles.leftBackground}></img>
            <img src={Assets.rightbg} alt="" className={styles.rightBackground}></img>
            <img src={Assets.bluewave} alt="" className={styles.blueWave}></img>
            <img src={Assets.yellowwave} alt="" className={styles.yellowWave}></img>
            <img src={Assets.goldstar} alt="" className={styles.goldStar}></img>

        </section>
    )
}