import React, { useState } from "react";
import styles from "./LandingBanner.module.scss";
import Assets from "../../../../layout/assets";
import LandingBannerForm from "../../../LandingBannerForm/LandingBannerForm";
import LearnPopUp from "./LearnPopUp";

export default function LandingBanner(){
  const [addModalShow, setAddModalShow] = useState(false);
    return(
        <section className={styles.LandingBannerWrap} id="LandingBanner">
            <div className="container">
                <div className={`row row-cols-xl-2 ${styles.customRow}`}>
                    <div className={styles.textWrap}>
                        <img src={Assets.waveimg} className={styles.waveimage} alt="img"></img>
                        <p><span>Kinderbridge Pilot Program</span></p>
                        <h1><span>Start Your Own Kinderbridge International Preschool Today!</span></h1>
                        {/* <PopupButton
                          id="HOQyhiU9"
                          className="btn btn-primary"
                        >
                        LEARN MORE
                        </PopupButton> */}
                        {/* <Button id="HOQyhiU9"
                            className="btn btn-primary" onClick={(e)=>setAddModalShow(true)}>
                            SIGN UP
                        </Button> */}
                        {/* <figure>
                            <img src={Assets.LandingBanner} className={styles.LandingBannerImage}></img>
                        </figure> */}
                        <img src={Assets.pinkimg} className={styles.pinkCircle} alt="img"></img>
                    </div>
                    <div className={styles.imageWrap}>
                        <LandingBannerForm />
                    </div>
                </div>
            </div>
            <img src={Assets.greenimg} className={styles.greenCircle} alt="img"></img>
            <img src={Assets.yellowimg} className={styles.yellowimage} alt="img"></img>
            <img src={Assets.waveimg} className={styles.waveimagePositioned} alt="img"></img>
            <LearnPopUp
                show={addModalShow}
                onHide={() => setAddModalShow(false)}
            />         
        </section>
        
    )
}
