import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import BlogCard from "../BlogCard";
import useFetch from "../../hooks/useFetch";
import { API_URI } from "../../helpers/constants";
import { Link } from "react-router-dom";

export default function BlogSLider() {
  const { data: blogData } = useFetch(`${API_URI}/blog-cards`);

  return (
    <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {blogData?.map((item, index) => {
          return (
            <SwiperSlide key={item.id}>
              <Link to={`/blogs/${item.slug}`}>
                <BlogCard type={"inner"} data={item} key={item.id} />
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
