import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./containers/HomePage";
import AboutUsPage from "./containers/AboutUsPage";
import LandingPage from "./containers/LandingPage";
import ContactUs from "./containers/ContactUs";
import Blogs from "./containers/BlogPage";
import BlogDetailPage from "./containers/BlogDetailPage";
import PrivacyPolicyPage from "./containers/PrivacyPolicyPage";
import ScrollToTop from "./components/ScrollToTop";
import "./styles/common.scss";
import { FullLoader } from "../src/components/FullLoader/Loader";
import { REDIRECTIONS } from "./helpers/redirectionFiles";
const PageNotFound = React.lazy(() => import("./containers/PageNotFound"));
const Layout = React.lazy(() => import("./Hoc/Layout"));
// const loading = () => <div>Loading...</div>;


function App() {
  const currentURL = window.location.pathname;

  for (let i = 0; i < REDIRECTIONS.length; i++) {
    const { source, target } = REDIRECTIONS[i];
    if (currentURL === source) {
      window.location.replace(target)
    }
  }
  
  return (
    <Suspense fallback={<FullLoader />}>
      <Router>
        {/* <Suspense fallback={loading()}> */}
        <ScrollToTop>
          <Routes>
            <Route
              path="/sign-up-indore"
              exact
              element={<Layout component={LandingPage} />}
            />
            <Route path="/" exact element={<Layout component={Home} />} />
            <Route
              path="/about"
              exact
              element={<Layout component={AboutUsPage} />}
            />
            <Route
              path="/contactus"
              exact
              element={<Layout component={ContactUs} />}
            />
            <Route path="/blogs" exact element={<Layout component={Blogs} />} />
            <Route
              path="/blogs/:slug"
              exact
              element={<Layout component={BlogDetailPage} />}
            />
            {/* <Route
            path="/blogdetails"
            exact
            element={<Layout component={index} />}
          /> */}
            <Route
              path="/privacypolicy"
              exact
              element={<Layout component={PrivacyPolicyPage} />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ScrollToTop>
        {/* </Suspense> */}
      </Router>
    </Suspense>
  );
}

export default App;
