import React from "react";
import { useSelector } from "react-redux";
import { API_URI } from "../../../helpers/constants";
import Assets from "../../../layout/assets";
import styles from "./aboutUsVision.module.scss";

export default function AboutUsvision() {
  const data = useSelector((state) => state.file.aboutPageData);

  return (
    <section className={styles.AboutUsVisionWrapper}>
      <div className="container">
        <div className={`row ${styles.custRow}`}>
          <div className={`col-md-6 col-sm-12`}>
            <div className={styles.visionImgWrap}>
              <figure>
                <img
                  src={`${API_URI}${data?.[0]?.ourVisionImage?.url}`}
                  alt="aside-image"
                ></img>
              </figure>
            </div>
          </div>
          <div className={`col-md-6 col-sm-12`}>
            <div className={styles.visionContentWrap}>
              <h2>{data?.[0]?.ourVisionHeading}</h2>
              <p>{data?.[0]?.ourVisionDescription}</p>
            </div>
          </div>
          <div className={`col-md-6 col-sm-12`}>
            <div className={styles.missionContentWrap}>
              <h2>{data?.[0]?.ourMissionHeading}</h2>
              <p>
                {data?.[0]?.ourMissionDescription}
              </p>
            </div>
          </div>
          <div className={`col-md-6 col-sm-12`}>
            <div className={styles.missionImgWrap}>
              <figure>
                <img src={`${API_URI}${data?.[0]?.ourMissionImage?.url}`} alt="aside-image"></img>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <img
        src={Assets.aboutvisionstar}
        className={styles.aboutvisionstar}
        alt=""
      />
      <img
        src={Assets.aboutvisionvector}
        className={styles.aboutvisionvector}
        alt=""
      />
    </section>
  );
}
