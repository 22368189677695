// -------header----------
import logo from "../assets/images/header/logo.svg";
import globe from "../assets/images/header/language-globe.svg";
import map from "../assets/images/header/map.svg";

// ------------footer----------
import facebook from "../assets/images/footer/facebook.svg";
import instagram from "../assets/images/footer/instagram.svg";
import linkedin from "../assets/images/footer/linkedin.svg";


// -------------landing-page----------
import LandingBanner from "../assets/images/landing/banner/banner_img.png";
import waveBorder from "../assets/images/landing/about/wave-border.svg";
import drawing from "../assets/images/landing/about/drawing.png";
import waveBorderCareer from "../assets/images/landing/about/wave-border.svg";

import step1image from "../assets/images/landing/services/img1.png";
import step2image from "../assets/images/landing/services/img2.png";
import step3image from "../assets/images/landing/services/img3.png";
import step4image from "../assets/images/landing/services/img4.png";
import cuttingImage from "../assets/images/landing/services/cutting.png";
import writingImage from "../assets/images/landing/services/writing.png";

import buildingImage from "../assets/images/landing/trust/trust-image.png";
import cardimg1 from "../assets/images/landing/trust/card-img1.png";
import cardimg2 from "../assets/images/landing/trust/card-img2.png";
import cardimg3 from "../assets/images/landing/trust/card-img3.png";

import cardImage1 from "../assets/images/landing/benefits/card-img1.svg";
import cardImage2 from "../assets/images/landing/benefits/card-img2.svg";
import cardImage3 from "../assets/images/landing/benefits/card-img3.svg";
import cardImage4 from "../assets/images/landing/benefits/card-img4.svg";

import mainImg from "../assets/images/landing/signup/main-image.png";


// ---------animation-images---------------
import pinkimg from "../assets/images/landing/banner/pinkcircle.png";
import greenimg from "../assets/images/landing/banner/greencircle.png";
import waveimg from "../assets/images/landing/banner/waveimage.png";
import yellowimg from "../assets/images/landing/banner/yellowbg.png";


import pinkimage from "../assets/images/landing/career/pink-circle.png";
import waveimage from "../assets/images/landing/career/wave-top.png"

import bluestar from "../assets/images/landing/services/bluestar.png";
import goldstar from "../assets/images/landing/services/yellowstar.png";

import pinkstar from "../assets/images/landing/trust/pinkstar.png";

import leftbg from "../assets/images/landing/benefits/left-bg.png";
import rightbg from "../assets/images/landing/benefits/right-bg.png";
import bluewave from "../assets/images/landing/benefits/bluewave.png";
import yellowwave from "../assets/images/landing/benefits/yellowwave.png";


// ---------------home-page--------------------------

import bannerImage from "../assets/images/home/banner/banner-image.png";
import waveImage from "../assets/images/home/banner/banner-wave.png";
import bannerGreen from "../assets/images/home/banner/banner-green-circle.png";
import bannerBackground from "../assets/images/home/banner/background-circle.png";


import card1img from "../assets/images/home/features/card1.png";
import card2img from "../assets/images/home/features/card2.png";
import card3img from "../assets/images/home/features/card3.png";


import mainImage from "../assets/images/home/pilot/main-image.png";

import floatImage from "../assets/images/home/tools/main-image.png";

import partnerImage from "../assets/images/home/partner/main-image.png";

import tab1Image from "../assets/images/home/partner/tab-1.png";
import tab2Image from "../assets/images/home/partner/tab-2.png";
import tab3Image from "../assets/images/home/partner/tab-3.png";

import sectionImage from "../assets/images/home/approach/main-image.png";
import tabImage1 from "../assets/images/home/approach/tab-1.png";
import tabImage2 from "../assets/images/home/approach/tab-2.png";

import redRing from "../assets/images/home/features/ring.png";
import star from "../assets/images/home/features/star.png";

import mainFloatingImage from "../assets/images/home/ensure/main-floating-image.png";

import aboutBannerImage from "../assets/images/aboutus/banner/aboutbanner.png.png";

import visionimage from "../assets/images/aboutus/vision/visionimage.png.png";
import missionimage from "../assets/images/aboutus/vision/missionimage.png.png";
import rowimg1 from "../assets/images/aboutus/story/row-img1.png";
import rowimg2 from "../assets/images/aboutus/story/row-img2.png";
import rowimg3 from "../assets/images/aboutus/story/row-img3.png";


import topic1img from "../assets/images/aboutus/topics/topic1.svg";
import topic2img from "../assets/images/aboutus/topics/topic2.svg";
import topic3img from "../assets/images/aboutus/topics/topic3.svg";
import topic4img from "../assets/images/aboutus/topics/topic4.svg";
import topic5img from "../assets/images/aboutus/topics/topic5.svg";
import topic6img from "../assets/images/aboutus/topics/topic6.svg";
import topic7img from "../assets/images/aboutus/topics/topic7.svg";
import topic8img from "../assets/images/aboutus/topics/topic8.svg";
import topic9img from "../assets/images/aboutus/topics/topic9.svg";

import serviceVectorBg from "../assets/images/home/service/vectorBG.png";
import serviceStar from "../assets/images/home/service/star.png";



import inspireImg from "../assets/images/aboutus/inspire/main-image.png";

import contactBannerImg from "../assets/images/contact/banner/main-image.png";
import formImg from "../assets/images/contact/form/form-image.png";

import bgVector from "../assets/images/home/pilot/background.png";


import aboutbanneryellowbg from "../assets/images/aboutus/banner/yellowbg.png";
import aboutbannerorangecircle from "../assets/images/aboutus/banner/orangecircle.png";
import aboutbannerhalfcircle from "../assets/images/aboutus/banner/halfcircle.png";


import blogredcircle from "../assets/images/blog/blogbanner/red-circircle.svg";
import bloggreencircle from "../assets/images/blog/blogbanner/greencircle.svg";
import blogwave from "../assets/images/blog/blogbanner/absolutewave.svg";
import blogyellowcircle from "../assets/images/blog/blogbanner/yellowcircle.svg";


import blogtabredstar from "../assets/images/blog/blogtabs/redstar.svg";
import blogtabreddot from "../assets/images/blog/blogtabs/reddot.svg";
import blogtabcard1img from "../assets/images/blog/blogtabs/cardimg1.png";
import blogtabcard2img from "../assets/images/blog/blogtabs/cardimg2.png";
import blogtabcard3img from "../assets/images/blog/blogtabs/cardimg3.png";
import blogtabcard4img from "../assets/images/blog/blogtabs/cardimg4.png";
import blogtabcard5img from "../assets/images/blog/blogtabs/cardimg5.png";

import bloginspiremainimage from "../assets/images/blog/bloginspire/mainimage.png";

import aboutvisionvector from "../assets/images/aboutus/vision/vectorbg.png";
import aboutvisionstar from "../assets/images/aboutus/vision/orangestar.png";

import aboutstoryyellow from "../assets/images/aboutus/story/yellowcircle.png";
import aboutstorystar from "../assets/images/aboutus/story/greenstar.png";
import aboutstoryvector from "../assets/images/aboutus/story/vector.png";

import aboutpilotwave from "../assets/images/aboutus/pilot/pinkwave.png";
import aboutpilotcircle from "../assets/images/aboutus/pilot/orangecircle.png";
import aboutpilotfly from "../assets/images/aboutus/pilot/fly.png";

import blogdetailfooterimg from "../assets/images/blogdetail/footer-child.png";

import blogdetalorangecircle from "../assets/images/blogdetail/orangecircle.png";
import blogdetailorangestar from "../assets/images/blogdetail/orangestar.png";
import blogdetailhalfcircle from "../assets/images/blogdetail/greenhalfcircle.png";
import blogdetailorangewave from "../assets/images/blogdetail/orangewave.png";
import blogdetailredcircle from "../assets/images/blogdetail/redcircle.png";


const Assets = {
    blogdetalorangecircle,
    blogdetailorangestar,
    blogdetailhalfcircle,
    blogdetailorangewave,
    blogdetailfooterimg,
    blogdetailredcircle,
    aboutpilotfly,
    aboutpilotcircle,
    aboutpilotwave,
    aboutstoryyellow,
    aboutstorystar,
    aboutvisionstar,
    aboutvisionvector,
    aboutstoryvector,
    logo,
    globe,
    map,
    facebook,
    instagram,
    linkedin,
    LandingBanner,
    waveBorder,
    drawing,
    waveBorderCareer,
    step1image,
    step2image,
    step3image,
    step4image,
    cuttingImage,
    writingImage,
    buildingImage,
    cardimg1,
    cardimg2,
    cardimg3,
    cardImage1,
    cardImage2,
    cardImage3,
    cardImage4,
    mainImg,
    pinkimg,
    greenimg,
    waveimg,
    yellowimg,
    pinkimage,
    waveimage,
    bluestar,
    goldstar,
    pinkstar,
    leftbg,
    rightbg,
    bluewave,
    yellowwave,
    bannerImage,
    waveImage,
    bannerGreen,
    bannerBackground,
    card1img,
    card2img,
    card3img,
    mainImage,
    floatImage,
    partnerImage,
    tab1Image,
    tab2Image,
    tab3Image,
    sectionImage,
    tabImage1,
    tabImage2,
    mainFloatingImage,
    aboutBannerImage,
    visionimage,
    missionimage,
    rowimg1,
    rowimg2,
    rowimg3,
    topic1img,
    topic2img,
    topic3img,
    topic4img,
    topic5img,
    topic6img,
    topic7img,
    topic8img,
    topic9img,
    inspireImg,
    contactBannerImg,
    formImg,
    redRing,
    star,
    bgVector,
    serviceVectorBg,
    serviceStar,
    blogredcircle,
    bloggreencircle,
    blogwave,
    blogyellowcircle,
    blogtabredstar,
    blogtabreddot,
    blogtabcard1img,
    blogtabcard2img,
    blogtabcard3img,
    blogtabcard4img,
    blogtabcard5img,
    bloginspiremainimage,
    aboutbanneryellowbg,
    aboutbannerorangecircle,
    aboutbannerhalfcircle,


}

export default Assets;