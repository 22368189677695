import React from "react";
import styles from "./HomePageEnsure.module.scss";
import Assets from "../../../../layout/assets";
import { Link } from "react-router-dom";
import { API_URI } from "../../../../helpers/constants";
import { useSelector } from "react-redux";

export default function HomePageEnsure() {
  const ensure = useSelector((state) => state.file.homePageData);

  return (
    <section className={styles.HomePageEnsureSection}>
      <div className="container">
        <h2>{ensure?.[0]?.sectionSixHeading}</h2>
        <p>{ensure?.[0]?.sectionSixDescription}</p>
        <Link to="/contactus" className="btn btn-secondary">
          {ensure?.[0]?.sectionSixLearnMoreButton}
        </Link>
        <div className={styles.foatingImage}>
          <figure>
            <img src={`${API_URI}${ensure?.[0]?.sectionSixImage?.url}`} alt="overflow-image"></img>
          </figure>
        </div>
      </div>
    </section>
  );
}
