import React from "react";
import AboutUsBanner from "./AbousUsBanner/AboutUsBanner";
import AboutUsvision from "./AboutUsVision/AboutUsVision";
import AboutUsStory from "./AboutUsStory/AboutUsStory";
import AboutUsPilot from "./AboutUsPilot/AboutUsPilot";
import AboutUsTopics from "./AboutUsTopics/AboutUsTopics";
import AboutUsInspire from "./AboutUsInspire/AboutUsInspire";

export default function AboutUs({bannerData,topics}){
    return(
        <>
            <AboutUsBanner data={bannerData}></AboutUsBanner>
            <AboutUsvision></AboutUsvision>
            <AboutUsStory></AboutUsStory>
            <AboutUsPilot></AboutUsPilot>
            <AboutUsTopics data={topics}></AboutUsTopics>
            <AboutUsInspire></AboutUsInspire>
        </>
    )
}