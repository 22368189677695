import React from "react";
import styles from "./LandingTrust.module.scss";
import Assets from "../../../../layout/assets";

export default function LandingTrust() {
    return ( 
        <section className={styles.LandingTrustSection}>
            <div className="container">
                <div className={styles.headWrap}>
                    <div className={styles.textWrap}>
                        <h2>An educational partner you can trust</h2>
                        <p>We have over 30 years of experience running preschools, daycares, and nurseries in Canada, India, and the Middle East, coupled with an innovative, proven approach to early childhood education. </p>
                    </div>
                    <div className={styles.imageWrap}>
                        <figure>
                            <img src={Assets.buildingImage} alt="building-img" />
                        </figure>
                    </div>
                </div>
                <div className={styles.cardWrap}>
                    <div className={styles.cards}>
                        <div className={styles.card}>
                            <figure>
                                <img src={Assets.cardimg1} alt="" />
                            </figure>
                            <h4>Training and operational support</h4>
                            <p>Learn to design your timetable, navigate conversations with parents, and run your business via online training sessions, books, video tutorials, and our Kinderbridge manual.</p>
                        </div>
                    </div>
                    <div className={styles.cards}>
                        <div className={styles.card}>
                            <figure>
                                <img src={Assets.cardimg2} alt="" />
                            </figure>
                            <h4>Design Your Space</h4>
                            <p>We’ll help you plan a layout that makes the most of your classroom so you can deliver inspiring lessons day in and day out.</p>
                        </div>
                    </div>
                    <div className={styles.cards}>
                        <div className={styles.card}>
                            <figure>
                                <img src={Assets.cardimg3} alt="" />
                            </figure>
                            <h4>Networking Assistance</h4>
                            <p>Enlist on the Kinderbridge website and connect with our community of early childhood educators.</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src={Assets.pinkstar} alt="img" className={styles.pinkStar}></img>
        </section>
    )
}