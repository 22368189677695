import React from "react";
import { API_URI } from "../../../../helpers/constants";
import styles from "./ContactUsBanner.module.scss";

export default function ContactUsBanner({ data }) {
  return (
    <section className={styles.ContactUsBanner}>
      <div className="container">
        <div className="row row-cols-md-2">
          <div className={styles.ImgWrap}>
            <figure>
              <img
                src={`${API_URI}${data?.[0]?.bannerImage?.url}`}
                alt="banner-Img"
              />
            </figure>
          </div>
          <div className={styles.ContentWrap}>
            <h1>{data?.[0]?.bannerHeading}</h1>
            <p>{data?.[0]?.subHeading}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
