import React from "react";
import BlogDetailing from "./sections/BlogDetailing/BlogDetailing";
import BlogReply from "../BlogReply/BlogReply";
import BlogDetailPageFooter from "../BlogDetailFooter";
import useFetch from "../../hooks/useFetch";
import { API_URI } from "../../helpers/constants";

export default function BlogDetailPage({ data }) {
  const { loading, data: footer } = useFetch(`${API_URI}/blog-detail-footers`);
  
  return (
    <>
      <BlogDetailing data={data}></BlogDetailing>
      <BlogReply></BlogReply>
      <BlogDetailPageFooter data={footer}></BlogDetailPageFooter>
    </>
  );
}
