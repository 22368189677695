import React from "react";
import styles from "./CareerCard.module.scss";

export default function CareerCard({title, description, theme}){
    return(
        <div className={`${styles.card} ${theme=="primary"?styles.card_primary:theme=="secondary"?styles.card_secondary:""}`}>
            <div className={styles.card_body}>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    )
}