import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import styles from "./BlogReply.module.scss";
import { Formik } from "formik";
import Button from "react-bootstrap/esm/Button";
import { API_URI } from "../../helpers/constants";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";

export default function BlogReply() {
  const textRegex = /^[aA-zZ\s]+$/
  const blogSchema = yup.object().shape({
    comments: yup.string().trim().strict(true),
    name: yup
      .string()
      .trim()
      .strict(true).matches(textRegex,{ message: "Enter a valid name" })
      .required("Required Field"),
    email: yup
      .string()
      .email("Please enter a valid Email Address!")
      .required("Required Field"),
    website: yup.string().trim().strict(true),
  });

  const OnReplyFormSubmit = (data, { resetForm }) => {

    const formData = {
      comments: data.comments,
      name: data.name,
      email: data.email,
      website: data.website,
    };
    axios
      .post(`${API_URI}/reply-forms`, formData)
      .then((res) => {
        resetForm();
        if (res?.status === 200) {
          toast("Your message has been sent successfuly !");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong !");
      });
    resetForm();
  };

  return (
    <section className={styles.BlogReplySection}>
      <ToastContainer />
      <div className="container">
        <div className={styles.replyWrapper}>
          <h6>LEAVE A REPLY</h6>
          <Formik
            initialValues={{
              comments: "",
              name: "",
              email: "",
              website: "",
            }}
            onSubmit={OnReplyFormSubmit}
            validationSchema={blogSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <FloatingLabel controlId="floatingTextarea2" label="Comments">
                    <Form.Control
                      as="textarea"
                      name="comments"
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.comments && touched.comments
                          ? styles.inputError
                          : ""
                      }
                      placeholder="Leave a comment here"
                      style={{ height: "300px" }}
                    />
                    {errors.comments && touched.comments ? (
                    <div className={styles.errorMessage}>{errors.comments}</div>
                  ) : null}
                  </FloatingLabel>
                  
                </Form.Group>
                <div className={styles.inputWrapper}>
                  <FloatingLabel controlId="floatingInput" label="Name*">
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? styles.inputError : ""
                      }
                      placeholder="name"
                    />
                    {errors.name && touched.name ? (
                    <div className={styles.errorMessage}>{errors.name}</div>
                  ) : null}
                  </FloatingLabel>
                  
                  <FloatingLabel controlId="floatingInput" label="Email*">
                    <Form.Control
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.email && touched.email ? styles.inputError : ""
                      }
                      placeholder="name@example.com"
                    />
                    {errors.email && touched.email ? (
                    <div className={styles.errorMessage}>{errors.email}</div>
                  ) : null}
                  </FloatingLabel>
                  
                  <FloatingLabel controlId="floatingInput" label="Website*">
                    <Form.Control
                      type="text"
                      name="website"
                      value={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.website && touched.website
                          ? styles.inputError
                          : ""
                      }
                      placeholder="Website"
                    />
                    {errors.website && touched.website ? (
                    <div className={styles.errorMessage}>{errors.website}</div>
                  ) : null}
                  </FloatingLabel>
                  
                </div>
                <div className="text-center">
                  <Button type="submit">Submit</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
}
