import React from "react";
import styles from "./ContactUsForm.module.scss";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URI } from "../../../../helpers/constants";

export default function ContactUsForm({data}) {
  
  const validateConatct = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    email: yup
      .string()
      .email("Please enter a valid Email Address!")
      .required("Email is required!"),
    city: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("City is required"),
  });
  const initialValues = {
    name: "",
    email: "",
    city: "",
    comment: "",
  };
  const handleContact = (data, { resetForm }) => {
    axios
      .post("https://crm-integration.techlabz.in/api/careerFormKb", data, {
        headers: {
          Auth: "CODE_INFOTECH_AUTH",
        },
      })
      .then((res) => {
        resetForm();
        if (res?.data?.status === "200") {
          toast.success("Your message has been sent successfuly !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <section className={styles.ContactUsFormSection}>
      <ToastContainer />
      <div className="container">
        <div className="row row-cols-md-2">
          <Formik
            initialValues={initialValues}
            validationSchema={validateConatct}
            onSubmit={handleContact}
          >
            {({
              handleSubmit,
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={styles.FormWrap}>
                  <div className={styles.formContentWrapper}>
                    <h3>Send a message</h3>
                    <FloatingLabel controlId="floatingInput" label="Name">
                      <Form.Control
                        type="text"
                        placeholder="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.name && touched.name ? styles.inputError : null
                        }
                      />
                      {errors.name && touched.name ? (
                        <div className={styles.errorMessage}>{errors.name}</div>
                      ) : null}
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingInput" label="Email">
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.email && touched.email
                            ? styles.inputError
                            : null
                        }
                      />
                      {errors.email && touched.email ? (
                        <div className={styles.errorMessage}>
                          {errors.email}
                        </div>
                      ) : null}
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingInput" label="City">
                      <Form.Control
                        type="text"
                        placeholder="city"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.city && touched.city ? styles.inputError : null
                        }
                      />
                      {errors.city && touched.city ? (
                        <div className={styles.errorMessage}>{errors.city}</div>
                      ) : null}
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingTextarea" label="Message">
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        name="comment"
                        value={values.comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FloatingLabel>
                    <Button
                      type="submit"
                      variant="primary"
                      className={`btn btn-yellow ${styles.submitBtn}`}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className={styles.ContentWrap}>
            <h2>{data?.[0]?.heading}</h2>
            <p>
              {data?.[0]?.description}
            </p>
            <figure className={styles.FormFigure}>
              <img src={`${API_URI}${data?.[0]?.image?.url}`} alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
