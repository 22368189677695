import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import fileReducer from './file'

const store = configureStore({
    reducer : {
        file:fileReducer
    },
    middleware: [thunk],
})

export default store
