import React from "react";
import Assets from "../../layout/assets";
import styles from "./BlogDetailFooter.module.scss";
import { Link } from "react-router-dom";
import { API_URI } from "../../helpers/constants";

export default function BlogDetailFooter({ data }) {
  
  return (
    <section className={styles.BlogDetailFooterSection}>
      <div className="container">
        <div className="row row-cols-lg-2">
          <div className={styles.ContentWrap}>
            <h2>{data?.[0]?.heading}</h2>
            <Link to="/contactus" className="btn btn-yellow">
                {data?.[0]?.LearnMoreButton}
            </Link>
          </div>
          <div className={styles.ImgWrap}>
            <img
              src={`${API_URI}${data?.[0]?.image?.url}`}
              className={styles.blogdetailfooterimg}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
