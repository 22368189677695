import { createSlice } from "@reduxjs/toolkit";

export const fileSlice = createSlice({
  name: "file",
  initialState: {
    homePageData: null,
    aboutPageData:null,
    blogs:null
  },
  reducers: {
    setHomePage: (state, action) => {
      return {
        ...state,
        homePageData: action.payload,
      };
    },
    setAboutPage: (state, action) => {
      return {
        ...state,
        aboutPageData: action.payload,
      };
    },
    setBlogs : (state,action) => {
      return {
        ...state,
        blogs:action.payload
      }
    }
  },
});

export const { setHomePage,setAboutPage,setBlogs } = fileSlice.actions;

export default fileSlice.reducer;
