import Assets from "../../../../layout/assets";
import styles from "./BlogTabs.module.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BlogCard from "../../../BlogCard";
import { Link } from "react-router-dom";

export default function BlogTabs({ blogs }) {
  return (
    <section className={styles.BlogTabsSection}>
      <div className="container">
        <Tabs defaultActiveKey="Blogs" id="uncontrolled-tab-example">
          {/* <Tab eventKey="Resources" title="Resources" disabled></Tab> */}
          <Tab eventKey="Blogs" title="Blogs">
            <div
              className={`${styles.Tab1ContentWrap} row row-cols-md-2 row-cols-xl-3`}
            >
              {blogs?.map((item) => (
                <div className={styles.itemWrapper} key={item.id}>
                  <Link to={`${item.slug}`}>
                    <BlogCard data={item} key={item.id} />
                  </Link>
                </div>
              ))}
            </div>
          </Tab>
        </Tabs>
      </div>
      <img src={Assets.blogtabredstar} className={styles.blogtabredstar}></img>
      <img src={Assets.blogtabreddot} className={styles.blogtabreddot}></img>
    </section>
  );
}
