import React from "react";
import styles from "./HomePageService.module.scss";
import Assets from "../../../../layout/assets";
import CareerCard from "../../../CareerCard";

export default function HomePageService({ data }) {
  return (
    <section className={styles.HomePageServiceSection}>
      <div className="container">
        <h2>{data?.[0]?.title}</h2>
        <div className={`row row-cols-md-2 row-cols-lg-3 ${styles.cardWrap}`}>
          {data?.map((service, idx) => (
            <div key={service.id}>
              <CareerCard
                theme="secondary"
                title={service.heading}
                description={service.description}
              ></CareerCard>
            </div>
          ))}
        </div>
        <figure className={styles.vectorBgWrapper}>
          <img src={Assets.serviceVectorBg} alt="img"></img>
        </figure>
        <figure className={styles.starBgWrapper}>
          <img src={Assets.serviceStar} alt="img"></img>
        </figure>
      </div>
    </section>
  );
}
