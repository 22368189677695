import React from "react";
import styles from "./BlogInspire.module.scss";
import { Link } from "react-router-dom";
import { API_URI } from "../../../../helpers/constants";

export default function BlogInspire({ data }) {
    
  return (
    <section className={styles.BlogInspireSection}>
      <div className="container">
        <div className={`row row-cols-lg-2 ${styles.custRow}`}>
          <div className={styles.contentWrap}>
            <h2>{data?.[0]?.inspireHeading}</h2>
            <Link to="/contactus" className="btn btn-secondary">
              {data?.[0]?.inspireLearnMoreButton}
            </Link>
          </div>
          <div className={styles.imageWrap}>
            <figure>
              <img src={`${API_URI}${data?.[0]?.inspireImage?.url}`} alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
