import React from "react";
import styles from "./HomePagePilot.module.scss";
import Assets from "../../../../layout/assets";
import { useSelector } from "react-redux";
import {API_URI} from '../../../../helpers/constants';

export default function HomePagePilot(){
  const pilot = useSelector((state) => state.file.homePageData);

    return(
        <section className={styles.HomePagePilotSection}>
            <div className="container">
                <div className="row">
                    <div className={`col-lg-8 col-sm-12 ${styles.TextWrap}`}>
                        <figure>
                            <img src={Assets.waveImage} alt="wave-img"></img>
                        </figure>
                        <h2>{pilot?.[0]?.sectionTwoHeading}</h2>
                        <h3>{pilot?.[0]?.sectionTwoSubHeading}</h3>
                        <p>{pilot?.[0]?.sectionTwoDescription}</p>
                    </div>
                    <div className={`col-lg-4 col-sm-12 ${styles.ImageWrap}`}>
                        <figure>
                            <img src={`${API_URI}${pilot?.[0]?.sectionTwoImage?.url}`} alt="img"></img>
                        </figure>
                    </div>
                </div>
            </div>
            <figure className={styles.BackgroundVector}>
                <img src={Assets.bgVector} alt="img" />
            </figure>
        </section>
    )
}