import HomePage from "../../components/HomePage";
import useFetch from "../../hooks/useFetch";
import { API_URI } from "../../helpers/constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHomePage } from "../../redux/file";
import { FullLoader } from "../../components/FullLoader/Loader";
import { Helmet } from "react-helmet";

export default function Home() {
  const dispatch = useDispatch();

  //Fetching home page Datas
  const { data, loading } = useFetch(`${API_URI}/home-pages`);
  const { data: services } = useFetch(`${API_URI}/kinderpass-services`);
  const { data: onBoardings } = useFetch(`${API_URI}/home-page-on-boardings`);

  useEffect(() => {
    dispatch(setHomePage(data));
  }, [data]);

  

  return (
    <>
      {loading && <FullLoader />}
      {data && (
        <>
        <Helmet>
          <title>{data?.[0]?.SEO?.metaTitle}</title>
          <meta name="description" content={data?.[0]?.SEO?.metaDescription} />
          <link rel="canonical" href={data?.[0]?.SEO?.canonicalURL} />
          <meta property="og:title" content={data?.[0]?.SEO?.metaTitle}/>
          <meta name="og:description" content={data?.[0]?.SEO?.metaDescription} />
          <meta property="og:image" content={`${API_URI}${data?.[0]?.SEO?.metaImage?.url}`}/>
        </Helmet>

          <HomePage services={services} onBoardings={onBoardings}></HomePage>
        </>
      )}
    </>
  );
}
