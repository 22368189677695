import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import popUp from "./LearnPopUp.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LearnPopUp(props) {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    daycare: "",
    experience: "",
    getStarted: "",
  };
  const phoneNumberRegex = /^[0-9]{10}$/;
  const validatePopUp = yup.object().shape({
    firstName: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is required"),
    lastName: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("LastName is required"),
    email: yup
      .string()
      .email("Please enter a valid Email Address!")
      .required("Email is required!"),
    phoneNumber: yup
      .string()
      .min(4, "Mininum 4 digits required")
      .max(20, "Max limit reached")
      .matches(phoneNumberRegex, {
        message: "Please enter a valid Phone Number",
      })
      .required("Required field"),
    daycare: yup
      .string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
    experience: yup
      .string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
    getStarted: yup
      .string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
  });

  const handlePopUp = (data, { resetForm }) => {
    axios
      .post("https://crm-integration.techlabz.in/api/contactKbTeam", data, {
        headers: {
          Auth: "CODE_INFOTECH_AUTH",
        },
      })
      .then((res) => {
        resetForm();
        props.onHide();
        if (res?.data?.status === "200") {
          toast.success("Your message has been sent successfuly !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div>
      <Modal
        className={popUp.AutoPopupMain}
        {...props}
        dialogClassName={popUp.modalGroup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ToastContainer />
        <div className={popUp.formContentWrapper}>
          <Modal.Body>
            <Modal.Header style={{ padding: 0 }}>
              <Modal.Title id="contained-modal-title-vcenter">
                <>
                  <h3>Contact the Kinderbridge Team</h3>
                  <h6>We'd love to help you get started.</h6>
                </>
              </Modal.Title>
            </Modal.Header>
            <Formik
              initialValues={initialValues}
              validationSchema={validatePopUp}
              onSubmit={handlePopUp}
            >
              {({
                handleSubmit,
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={popUp.Main}>
                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Group>
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your first Name"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.firstName && touched.firstName
                                ? popUp.inputError
                                : null
                            }
                          />
                          {errors.firstName && touched.firstName ? (
                            <div className={popUp.errorMessage}>
                              {errors.firstName}
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your last Name"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.lastName && touched.lastName
                                ? popUp.inputError
                                : null
                            }
                          />
                          {errors.lastName && touched.lastName ? (
                            <div className={popUp.errorMessage}>
                              {errors.lastName}
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Group>
                          <Form.Label>Email ID</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter your ID"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.email && touched.email
                                ? popUp.inputError
                                : null
                            }
                          />
                          {errors.email && touched.email ? (
                            <div className={popUp.errorMessage}>
                              {errors.email}
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group>
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your Phone number"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.phoneNumber && touched.phoneNumber
                                ? popUp.inputError
                                : null
                            }
                          />
                          {errors.phoneNumber && touched.phoneNumber ? (
                            <div className={popUp.errorMessage}>
                              {errors.phoneNumber}
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <Form.Group>
                          <Form.Label>
                            Do you currently own a space that you can convert
                            into a daycare?
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="daycare"
                            value={values.daycare}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.daycare && touched.daycare
                                ? popUp.inputError
                                : null
                            }
                          />
                          {errors.daycare && touched.daycare ? (
                            <div className={popUp.errorMessage}>
                              {errors.daycare}
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <Form.Group>
                          <Form.Label>
                            Do you have experience caring for or teaching
                            children under 6 years old?
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="experience"
                            value={values.experience}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.experience && touched.experience
                                ? popUp.inputError
                                : null
                            }
                          />
                          {errors.experience && touched.experience ? (
                            <div className={popUp.errorMessage}>
                              {errors.experience}
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <Form.Group>
                          <Form.Label>
                            When would you like to get started?
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={values.getStarted}
                            name="getStarted"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.getStarted && touched.getStarted
                                ? popUp.inputError
                                : null
                            }
                          />
                          {errors.getStarted && touched.getStarted ? (
                            <div className={popUp.errorMessage}>
                              {errors.getStarted}
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <Button type="submit" variant="primary">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}

export default LearnPopUp;
