import React from "react";
import Assets from "../../../../layout/assets";
import styles from "./BlogBanner.module.scss";
import Markdown from 'react-markdown'

export default function BlogBanner({data}){
    return(
        <section className={styles.BlogBannerSection}>
            <div className="container">
                <h1>{data?.[0]?.bannerHeading}</h1>
                <Markdown>{data?.[0]?.bannerDescription}</Markdown>
            </div>
            <img src={Assets.blogredcircle}  className={styles.colorImage1} alt="" />
            <img src={Assets.bloggreencircle}  className={styles.colorImage2} alt="" />
            <img src={Assets.blogwave}  className={styles.colorImage3} alt=""/>
            <img src={Assets.blogyellowcircle}  className={styles.colorImage4} alt="" />
        </section>
   )
}