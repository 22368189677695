import ContactUsPage from "../../components/ContactUsPage";
import { API_URI } from "../../helpers/constants";
import useFetch from "../../hooks/useFetch";
import { FullLoader } from "../../components/FullLoader/Loader";
import { Helmet } from "react-helmet";

export default function ContactUs() {
  const { data: bannerData, loading: bannerLoading } = useFetch(
    `${API_URI}/contact-us-banners`
  );
  const { data, loading } = useFetch(`${API_URI}/contact-us-pages`);
  return (
    <>
      <Helmet>
        <title>{data?.[0]?.SEO?.metaTitle}</title>
        <meta name="description" content={data?.[0]?.SEO?.metaDescription} />
        <link rel="canonical" href={data?.[0]?.SEO?.canonicalURL} />
        <meta property="og:title" content={data?.[0]?.SEO?.metaTitle} />
        <meta name="og:description" content={data?.[0]?.SEO?.metaDescription} />
        <meta
          property="og:image"
          content={`${API_URI}${data?.[0]?.SEO?.metaImage?.url}`}
        />
      </Helmet>
      {bannerLoading && <FullLoader />}
      {loading && <FullLoader />}
      {data && bannerData && (
        <ContactUsPage bannerData={bannerData} data={data}></ContactUsPage>
      )}
    </>
  );
}
