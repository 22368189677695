import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import Spinner from "react-bootstrap/Spinner";
import popUp from "./LandingBannerForm.module.scss";
import * as yup from "yup";
import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

function LandingBannerForm(props) {
  const [loading,setLoading] = useState(false);
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    daycare: "",
    experience: "",
    getStarted: "",
  };
  const phoneNumberRegex = /^[0-9]{10}$/;
  const validatePopUp = yup.object().shape({
    firstName: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is required"),
    lastName: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("LastName is required"),
    email: yup
      .string()
      .email("Please enter a valid Email Address!")
      .required("Email is required!"),
    phoneNumber: yup
      .string()
      .min(4, "Mininum 4 digits required")
      .max(20, "Max limit reached")
      .matches(phoneNumberRegex, {
        message: "Please enter a valid Phone Number",
      })
      .required("Required field"),
    daycare: yup
      .string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
    experience: yup
      .string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
    getStarted: yup
      .string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
  });

  const handlePopUp = (data, { resetForm }) => {
    setLoading(true)
    axios
      .post("https://crm-integration.techlabz.in/api/contactKbTeam", data, {
        headers: {
          Auth: "CODE_INFOTECH_AUTH",
        },
      })
      .then((res) => {
        resetForm();
        setLoading(false)
        console.log(res?.data);
        // props.onHide();
        if (res?.data?.status === "200") {
          toast.success(
            "Thank You for your interest in Kinderbridge! Our team will get in touch with you shortly.",
            {
              position: toast.POSITION.TOP_RIGHT,
              className: popUp.toastMessage,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      })
      .catch((err) => {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div className={popUp.LandingBannerFormWrapper}>
      <ToastContainer />
      <h5>CONTACT THE KINDERBRIDGE TEAM</h5>
      <p>We’d love to help you get started.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validatePopUp}
        onSubmit={handlePopUp}
      >
        {({
          handleSubmit,
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={popUp.Main}>
              <div className="row">
                <div className={`col-12 col-sm-6 ${popUp.InputColumn}`}>
                  <Form.Group>
                    {/* <Form.Label>First Name</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.firstName && touched.firstName
                          ? popUp.inputError
                          : null
                      }
                    />
                    {errors.firstName && touched.firstName ? (
                      <div className={popUp.errorMessage}>
                        {errors.firstName}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className={`col-12 col-sm-6 ${popUp.InputColumn}`}>
                  <Form.Group>
                    {/* <Form.Label>Last Name</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.lastName && touched.lastName
                          ? popUp.inputError
                          : null
                      }
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className={popUp.errorMessage}>
                        {errors.lastName}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 col-sm-6 ${popUp.InputColumn}`}>
                  <Form.Group>
                    {/* <Form.Label>Email ID</Form.Label> */}
                    <Form.Control
                      type="email"
                      placeholder="Email ID"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.email && touched.email ? popUp.inputError : null
                      }
                    />
                    {errors.email && touched.email ? (
                      <div className={popUp.errorMessage}>{errors.email}</div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className={`col-12 col-sm-6 ${popUp.InputColumn}`}>
                  <Form.Group>
                    {/* <Form.Label>Phone Number</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.phoneNumber && touched.phoneNumber
                          ? popUp.inputError
                          : null
                      }
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <div className={popUp.errorMessage}>
                        {errors.phoneNumber}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${popUp.InputColumn}`}>
                  <Form.Group>
                    {/* <Form.Label>
                            Do you currently own a space that you can convert
                            into a daycare?
                          </Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Do you currently own a space
                            that you can convert into a daycare?"
                      name="daycare"
                      value={values.daycare}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.daycare && touched.daycare
                          ? popUp.inputError
                          : null
                      }
                    />
                    {errors.daycare && touched.daycare ? (
                      <div className={popUp.errorMessage}>{errors.daycare}</div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${popUp.InputColumn}`}>
                  <Form.Group>
                    {/* <Form.Label>
                            Do you have experience caring for or teaching <br></br>
                            children under 6 years old?
                          </Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Do you have experience caring for
                            or teaching children under 6 years old?"
                      name="experience"
                      value={values.experience}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.experience && touched.experience
                          ? popUp.inputError
                          : null
                      }
                    />
                    {errors.experience && touched.experience ? (
                      <div className={popUp.errorMessage}>
                        {errors.experience}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${popUp.InputColumn}`}>
                  <Form.Group>
                    {/* <Form.Label>
                            When would you like to get started?
                          </Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="When would you like to get started?"
                      value={values.getStarted}
                      name="getStarted"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.getStarted && touched.getStarted
                          ? popUp.inputError
                          : null
                      }
                    />
                    {errors.getStarted && touched.getStarted ? (
                      <div className={popUp.errorMessage}>
                        {errors.getStarted}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className={`col-sm-12 ${popUp.submitBtn}`}>
                  <Button onClick={handleSubmit} type="submit" variant="yellow">
                    {loading && (
                      <Spinner animation="border" variant="primary" size="sm" />
                    )}

                    {!loading && <>Submit</>}
                  </Button>
                  {/* {isSubmitting && (
                        <div className={popUp.SumbitMessage}>
                          Submitted Successfully 
                        </div>
                      )} */}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default LandingBannerForm;
