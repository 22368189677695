import React from "react";
import { FullLoader } from "../../components/FullLoader/Loader";
import PrivacyPage from "../../components/PrivacyPolicy";
import { API_URI } from "../../helpers/constants";
import useFetch from "../../hooks/useFetch";

export default function PrivacyPolicyPage() {
    const {data,loading} = useFetch(`${API_URI}/privacy-policies`)
    return(
        <>
        {loading && <FullLoader/>}
            {data && <PrivacyPage data={data}></PrivacyPage>}        
        </>
    )
}