import React from "react";
import styles from "./HomePageBanner.module.scss";
import Assets from "../../../../layout/assets";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {API_URI} from '../../../../helpers/constants';

export default function HomePageBanner() {
  const bannerData = useSelector((state) => state.file.homePageData);

  return (
    <section className={styles.homePageBannerSection}>
      <div className="container">
        <div className="row row-cols-md-2">
          <div className={styles.imageWrap}>
            <figure>
              <img src={`${API_URI}${bannerData?.[0]?.bannerImage?.url}`} alt="banner-img"></img>
            </figure>
          </div>
          <div className={styles.textWrap}>
            <h1>
              <span className={styles.outerSpan}>
                <span className={styles.InnerSpan}>{bannerData?.[0]?.bannerHeadingOne}</span>
              </span>
              <span className={styles.outerSpan}>
                <span className={styles.InnerSpan}>
                  {bannerData?.[0]?.bannerHeadingTwo}
                </span>
              </span>
            </h1>
            <p>
              <span>
                {bannerData?.[0]?.bannerDescription}
              </span>
            </p>
            <Link to="/contactus" className="btn btn-yellow">
              {bannerData?.[0]?.bannerLearnMoreButton}
            </Link>
            <figure>
              <img src={Assets.waveImage} alt="img"></img>
            </figure>
          </div>
        </div>
      </div>
      <img
        src={Assets.bannerBackground}
        alt="banner-bg-img"
        className={styles.bannerBackgroundImg}
      ></img>
      <img
        src={Assets.bannerGreen}
        alt="banner-bg-img"
        className={styles.bannerBackgroundImgGreen}
      ></img>
    </section>
  );
}
