import React from "react";
import styles from "./LandingServices.module.scss"
import Assets from "../../../../layout/assets";

export default function LandingServices() {
    return (
        <section className={styles.LandingServicesSection}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-sm-12">
                        <div className={styles.textWrap}>
                            <figure>
                                <img src={Assets.waveimg} className={styles.waveimage} alt="img"></img>
                            </figure>
                            <h2>The First 1000 Days Matter. Bridge the Gap with Kinderbridge</h2>
                            <div className={styles.steps}>
                                <div className={styles.imgWrap}>
                                    <figure>
                                        <img src={Assets.step1image} alt="img1" />
                                    </figure>
                                </div>
                                <div className={styles.textWrap}>
                                    <h3>The Tools Your Kids Need</h3>
                                    <p>Manage your curriculum and business with complimentary access to our industry-leading processes, software, and expertise. </p>
                                </div>
                            </div>
                            <div className={styles.steps}>
                                <div className={styles.imgWrap}>
                                    <figure>
                                        <img src={Assets.step2image} alt="img1" />
                                    </figure>
                                </div>
                                <div className={styles.textWrap}>
                                    <h3>KinderPass</h3>
                                    <p>An intuitive, all-in-one software platform that streamlines and simplifies your day-to-day with classroom management, billing & invoicing, HR & attendance management, and parental engagement. </p>
                                </div>
                            </div>
                            <div className={styles.steps}>
                                <div className={styles.imgWrap}>
                                    <figure>
                                        <img src={Assets.step3image} alt="img1" />
                                    </figure>
                                </div>
                                <div className={styles.textWrap}>
                                    <h3>Scientifically-Developed Learning</h3>
                                    <p>Our SMARTE curriculum is based on comprehensive scientific research into early childhood development. We’ve designed it to help children truly thrive, and we’re constantly refining and revising it to make it better.  </p>
                                </div>
                            </div>
                            <div className={styles.steps}>
                                <div className={styles.imgWrap}>
                                    <figure>
                                        <img src={Assets.step4image} alt="img1" />
                                    </figure>
                                </div>
                                <div className={styles.textWrap}>
                                    <h3>Standard Operating Procedures</h3>
                                    <p>We provide the Kinderbridge Manual with all the necessary processes and forms for running your preschool, daycare, or nursery, such as the student onboarding process, medical checkup process, daily inspection forms, and many others.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className={styles.imageWrap}>
                            <figure>
                                <img src={Assets.cuttingImage} alt="cutting-Img" />
                            </figure>
                            <figure className={styles.writingImage}>
                                <img src={Assets.writingImage} alt="writing-Img"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <img src={Assets.bluestar} className={styles.blueStar} alt="img"></img>
            <img src={Assets.goldstar} className={styles.goldenStar} alt="img"></img>
        </section>
    )
}