import React from "react";
import styles from "./PrivacyPolicy.module.scss";
import Markdown from 'react-markdown'

export default function Privacy({data}) {
    return(
        <section className={styles.PrivacyPolicySection}>
            <div className="container">
                <div className={styles.PrivacyWrapper}>
                    <h1>{data?.[0]?.title}</h1>
                    <Markdown>{data?.[0]?.content}</Markdown>
                </div>
            </div>
        </section>
    )
}