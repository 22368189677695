import React from "react"; 
import CareerCard from "../../../CareerCard";
import styles from "./LandingCareer.module.scss"; 
import Assets from "../../../../layout/assets";

export default function LandingCareer(){ 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
    return(
        <section className={styles.LandingCareerSection}>
            <div className="container">
                <div className={styles.childcareHead}>
                    <figure>
                        <img src={Assets.waveimage} alt="img"></img>
                    </figure>
                    <h2>Jumpstart Your Childcare Career</h2>
                    <p>Whether you’re a teacher, a daycare provider, a parent, or simply someone with a passion for early childhood education, Kinderbridge can help you make a difference for Indore’s kids. We’ll give you all the support, training, and materials you
                        need to provide high-quality preschool, daycare, or nursery education.</p>
                </div>
                <div className={`row row-cols-md-2 row-cols-lg-3 ${styles.cardWrap}`}>
                    <div><CareerCard theme="primary" title="Cultivate" description="Provide the children of Indore with the learning environment they so richly deserve as you pursue your passion. "/></div>
                    <div><CareerCard theme="primary" title="Educate" description="From licensing to business management, our experts will handle the technical work—leaving you free to focus on teaching Indorian children. "/></div>
                    <div><CareerCard theme="primary" title="Thrive" description="Build your educational business through Kinderbridge, and become part of a vibrant community of educators."/></div>
                </div>
                <div className={styles.signupButtonWrapper}>
                        {/* <PopupButton
                          id="HOQyhiU9"
                          className={`btn btn-primary ${styles.signupButton}`}
                        >
                        SIGN UP
                        </PopupButton> */}
                        <button onClick={()=>scrollToTop()} className="btn btn-primary">SIGN UP</button>
                </div>
                <img src={Assets.pinkimage} className={styles.pinkImage} alt="img"></img>
            </div>
        </section>
    ) 
}