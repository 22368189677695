import BlogBanner from "./sections/BlogBanner/BlogBanner";
import BlogTabs from "./sections/BlogTabs/BlogTabs";
import BlogInspire from "./sections/BlogInspire/BlogInspire";
import { useSelector } from "react-redux";


export default function BlogPage({pageData}){
  const blogs = useSelector((state) => state.file.blogs);
    return(
        <>
            <BlogBanner data={pageData}></BlogBanner>
            <BlogTabs blogs={blogs}></BlogTabs>
            {/* <BlogContact></BlogContact> */}
            <BlogInspire data={pageData}></BlogInspire>
        </>
    )
}