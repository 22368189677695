import HomePageBanner from "./sections/HomepageBanner/HomePageBanner";
import HomePageFeature from "./sections/HomePageFeatures/HomePageFeatures";
import HomePagePilot from "./sections/HomepagePilot/HomepagePilot";
import HomePageTool from "./sections/HomePageTool/HomePageTool";
import HomePageService from "./sections/HomePageService/HomePageService";
import HomepagePartner from "./sections/HomepagePartner/HomepagePartner";
import HomePageApproach from "./sections/HomePageApproach/HomePageApproach";
import HomePageOnboarding from "./sections/HomePageOnboarding/HomePageOnboarding";
import HomePageEnsure from "./sections/HomePageEnsure/HomePageEnsure";

export default function HomePage({services,onBoardings}) {
    return(
        <>
            <HomePageBanner></HomePageBanner>
            <HomePageFeature></HomePageFeature>
            <HomePagePilot></HomePagePilot>
            <HomePageTool></HomePageTool>
            <HomePageService data={services}></HomePageService>
            <HomepagePartner></HomepagePartner>
            <HomePageApproach></HomePageApproach>
            <HomePageOnboarding data={onBoardings}></HomePageOnboarding>
            <HomePageEnsure></HomePageEnsure>
        </>
    )
}