import React, { useRef, useState } from "react";
import styles from "./BlogDetailing.module.scss";
import BlogDetailSLider from "../../../BlogSlider";
import parse from "html-react-parser";
import Assets from "../../../../layout/assets";
import { API_URI } from "../../../../helpers/constants";
import useFetch from "../../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { FullLoader } from "../../../FullLoader/Loader";
import Markdown from "react-markdown";
import { Helmet } from "react-helmet";

export default function BlogDetailing() {
  const { slug } = useParams();
  const { loading, data } = useFetch(
    `${API_URI}/blog-cards?slug_eq=${slug}`
  );
  return (
    <>
      {loading && <FullLoader />}
      {data && (
        <>
          <Helmet>
            <title>{data?.[0]?.SEO?.metaTitle}</title>
            <meta
              name="description"
              content={data?.[0]?.SEO?.metaDescription}
            />
            <link rel="canonical" href={data?.SEO?.canonicalURL} />
            <meta property="og:title" content={data?.SEO?.metaTitle} />
            <meta
              name="og:description"
              content={data?.[0]?.SEO?.metaDescription}
            />
            <meta
              property="og:image"
              content={`${API_URI}${data?.[0]?.SEO?.metaImage?.url}`}
            />
          </Helmet>
          <section className={styles.BlogDetailingSection}>
            <div className="container">
              <div className="row row-cols-lg-2">
                <div
                  className={`col-lg-8 col-sm-12 ${styles.BlogContentSection}`}
                >
                  <div className={styles.BlogDetailDataWrapper}>
                    <figure>
                      <img
                        src={`${API_URI}${data?.[0]?.blogImage?.url}`}
                        className="w-100"
                        alt={data?.blogTitle}
                      />
                    </figure>
                    <h1>{data?.[0]?.blogTitle}</h1>
                    <div className="admin-content-area">
                      <Markdown>{parse(`${data?.[0]?.blogDescription}`)}</Markdown>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-lg-4 col-sm-12 ${styles.BlogSliderSection}`}
                >
                  <div className={styles.BlogSliderContainer}>
                    <BlogDetailSLider />
                  </div>
                </div>
              </div>
            </div>
            <img
              src={Assets.blogdetalorangecircle}
              alt=""
              className={styles.blogdetalorangecircle}
            />
            <img
              src={Assets.blogdetailorangestar}
              alt=""
              className={styles.blogdetailorangestar}
            />
            <img
              src={Assets.blogdetailhalfcircle}
              alt=""
              className={styles.blogdetailhalfcircle}
            />
            <img
              src={Assets.blogdetailorangewave}
              alt=""
              className={styles.blogdetailorangewave}
            />
            <img
              src={Assets.blogdetailredcircle}
              alt=""
              className={styles.blogdetailredcircle}
            />
          </section>
        </>
      )}
    </>
  );
}
