import React from "react";
import Assets from "../../../layout/assets";
import styles from "./AboutUsPilot.module.scss";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";

export default function AboutUsPilot() {
  const data = useSelector((state) => state.file.aboutPageData);

  return (
    <section className={styles.AboutUsPilotSection}>
      <div className="container">
        <h2>{data?.[0]?.pilotHeading}</h2>
        <div className={styles.pilotContentWrap}>
          <Markdown>
              {data?.[0]?.pilotDescription}
          </Markdown>
          <Link to="/contactus" className="btn btn-secondary">
            {data?.[0]?.pilotLearnMoreButton}
          </Link>
        </div>
      </div>
      <img src={Assets.aboutpilotfly} className={styles.aboutpilotfly} alt="" />
      <img
        src={Assets.aboutpilotcircle}
        className={styles.aboutpilotcircle}
        alt=""
      />
      <img
        src={Assets.aboutpilotwave}
        className={styles.aboutpilotwave}
        alt=""
      />
    </section>
  );
}
