import ContactUsBanner from "./sections/ContactUsBanner/ContactUsBanner";
import ContactUsForm from "./sections/ContactUsForm/ContactUsForm";

export default function ContactUsPage({bannerData,data}){
    return(
        <>
            <ContactUsBanner data={bannerData}></ContactUsBanner>
            <ContactUsForm data={data}></ContactUsForm>
        </>
    )
}