import { useEffect } from "react";
import BlogPage from "../../components/Blog";
import { FullLoader } from "../../components/FullLoader/Loader";
import { API_URI } from "../../helpers/constants";
import useFetch from "../../hooks/useFetch";
import { useDispatch } from "react-redux";
import { setBlogs } from "../../redux/file";
import { Helmet } from "react-helmet";

export default function Blogs() {
  const dispatch = useDispatch();
  const { loading, data } = useFetch(`${API_URI}/resources-pages`);
  const { loading: blogLoading, data: blogs } = useFetch(
    `${API_URI}/blog-cards`
  );

  useEffect(() => {
    dispatch(setBlogs(blogs));
  }, [blogs]);
  return (
    <>
      <Helmet>
        <title>{data?.[0]?.SEO?.metaTitle}</title>
        <meta name="description" content={data?.[0]?.SEO?.metaDescription} />
        <link rel="canonical" href={data?.[0]?.SEO?.canonicalURL} />
        <meta property="og:title" content={data?.[0]?.SEO?.metaTitle} />
        <meta name="og:description" content={data?.[0]?.SEO?.metaDescription} />
        <meta
          property="og:image"
          content={`${API_URI}${data?.[0]?.SEO?.metaImage?.url}`}
        />
      </Helmet>
      {loading && <FullLoader />}
      {blogLoading && <FullLoader />}
      {data && blogs && <BlogPage pageData={data}></BlogPage>}
    </>
  );
}
