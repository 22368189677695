import LandingBanner from "./sections/LandingBanner";
import LandingAbout from "./sections/LandingAbout";
import LandingCareer from "./sections/LandingCareer";
import LandingServices from "./sections/LandingServices";
import LandingTrust from "./sections/LandingTrust";
import LandingProcedures from "./sections/LandingProcedures";
import LandingBenefits from "./sections/LandingBenefits";
import LandingSignup from "./sections/LandingSignup";



export default function Landing() {
  return (
    <>
      <LandingBanner></LandingBanner>
      <LandingAbout></LandingAbout>
      <LandingCareer></LandingCareer>
      <LandingServices></LandingServices>
      <LandingTrust></LandingTrust>
      <LandingProcedures></LandingProcedures>
      <LandingBenefits></LandingBenefits>
      <LandingSignup></LandingSignup>
    </>
  )
}
