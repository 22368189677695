import React from "react";
import styles from "./HomePageApproach.module.scss";
import Assets from "../../../../layout/assets";
import { useSelector } from "react-redux";
import { API_URI } from "../../../../helpers/constants";

export default function HomePageApproach() {
  const approach = useSelector((state) => state.file.homePageData);
  
  return (
    <section className={styles.HomePageApproachSection}>
      <div className="container">
        <div className="row row-cols-md-2">
          <div className={styles.imgWrap}>
            <figure>
              <img
                src={`${API_URI}${approach?.[0]?.sectionFiveImage?.url}`}
                alt="main-image"
              ></img>
            </figure>
          </div>
          <div className={styles.contentWrap}>
            <h2>{approach?.[0]?.sectionFiveHeading}</h2>
            <p>{approach?.[0]?.sectionFiveDescription}</p>
            <div className={styles.tabWrap}>
              <div className={styles.tabContent}>
                <h3>{approach?.[0]?.sectionFiveChild1Heading}</h3>
                <p>{approach?.[0]?.sectionFiveChild1Description}</p>
              </div>
              <figure>
                <img
                  src={`${API_URI}${approach?.[0]?.sectionFiveChild1Image?.url}`}
                  alt="tab-image"
                ></img>
              </figure>
            </div>
            <div className={styles.tabWrap}>
              <div className={styles.tabContent}>
                <h3>{approach?.[0]?.sectionFiveChild2Heading}</h3>
                <p>
                 {approach?.[0]?.sectionFiveChild2Description}
                </p>
              </div>
              <figure>
                <img src={`${API_URI}${approach?.[0]?.sectionFiveChild2Image?.url}`} alt="tab-image"></img>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
