import React from "react";
import BlogDetail from "../../components/BlogDetail";

export default function BlogDetailPage() {

  return (
    <>
      <BlogDetail />
    </>
  );
}
