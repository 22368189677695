import React from "react";
import styles from "./HomePageFeatures.module.scss";
import Assets from "../../../../layout/assets";
import { useSelector } from "react-redux";
import { API_URI } from "../../../../helpers/constants";

export default function HomePageFeature() {
  const features = useSelector((state) => state.file.homePageData);

  return (
    <section className={styles.HomePageFeatureSection}>
      <div className="container">
        <h2>{features?.[0]?.sectionOneHeading}</h2>
        <p>{features?.[0]?.sectionOneDescription}</p>
        <div className={styles.cardRow}>
          <div className={styles.cardWrapper}>
            <div className={styles.cardContent}>
              <div className={styles.cardBody}>
                <figure>
                  <img
                    src={`${API_URI}${features?.[0]?.sectionOneCard1Image?.url}`}
                    alt="card-image"
                  ></img>
                </figure>
                <h4>{features?.[0]?.sectionOneCard1Heading}</h4>
                <p>{features?.[0]?.sectionOneCard1Description}</p>
              </div>
            </div>
          </div>
          <div className={styles.cardWrapper}>
            <div className={styles.cardContent}>
              <div className={styles.cardBody}>
                <figure>
                  <img
                    src={`${API_URI}${features?.[0]?.sectionOneCard2Image?.url}`}
                    alt="card-image"
                  ></img>
                </figure>
                <h4>{features?.[0]?.sectionOneCard2Heading}</h4>
                <p>{features?.[0]?.sectionOneCard2Description}</p>
              </div>
            </div>
          </div>
          <div className={styles.cardWrapper}>
            <div className={styles.cardContent}>
              <div className={styles.cardBody}>
                <figure>
                  <img
                    src={`${API_URI}${features?.[0]?.sectionOneCard3Image?.url}`}
                    alt="card-image"
                  ></img>
                </figure>
                <h4>{features?.[0]?.sectionOneCard3Heading}</h4>
                <p>{features?.[0]?.sectionOneCard3Description}</p>
              </div>
            </div>
          </div>
          <figure className={styles.BackgroundRing}>
            <img src={Assets.redRing} alt="background-image" />
          </figure>
          <figure className={styles.BackgroundStar}>
            <img src={Assets.star} alt="background-image" />
          </figure>
        </div>
      </div>
    </section>
  );
}
