import React from "react";
import styles from "./AboutUsBanner.module.scss";
import Assets from "../../../layout/assets";
import { API_URI } from "../../../helpers/constants";

export default function AboutUsBanner({ data }) {
  return (
    <section className={styles.AboutUsBannerSection}>
      <div className="container">
        <div className="row row-cols-md-2">
          <div className={styles.textWrap}>
            <h1>{data?.[0]?.Heading}</h1>
            <p>{data?.[0]?.description}</p>
            <img
              src={Assets.aboutbannerorangecircle}
              className={styles.aboutbannerorangecircle}
              alt=""
            />
          </div>
          <div className={styles.ImageWrap}>
            <figure>
              <img
                src={`${API_URI}${data?.[0]?.image?.url}`}
                alt="banner-img"
              ></img>
            </figure>
          </div>
        </div>
      </div>
      <img
        src={Assets.aboutbanneryellowbg}
        className={styles.aboutbanneryellowbg}
        alt=""
      />
      <img
        src={Assets.aboutbannerhalfcircle}
        className={styles.aboutbannerhalfcircle}
        alt=""
      />
    </section>
  );
}
