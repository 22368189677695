import styles from "./BlogCard.module.scss";
import parse from "html-react-parser";
import { API_URI } from "../../helpers/constants";

export default function BlogCard({ data, type }) {
  return (
    <div
      className={`${styles.tabCard} ${
        type === "inner" && styles.tabCard_inner
      }`}
    >
      <div className={styles.tabCardContent}>
        <div className={styles.tabCardBody}>
          <figure>
            <img
              src={`${API_URI}${data?.thumbImage?.url}`}
              alt={data?.blogTitle}
            />
          </figure>
          <div className={styles.cardText}>
            <h3>{data?.blogTitle}</h3>
            <p>
              {parse(
                `${data?.blogDescription
                  .replace(/(<([^>]+)>)/gi, "")
                  .substring(0, 200)}`
              )}
            </p>
            <a href="">View More</a>
          </div>
        </div>
      </div>
    </div>
  );
}
