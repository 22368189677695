import React from "react";
import { useSelector } from "react-redux";
import Assets from "../../../layout/assets";
import styles from "./AboutUsStory.module.scss";
import Markdown from "react-markdown";
import { API_URI } from "../../../helpers/constants";

export default function AboutUsStory() {
  const data = useSelector((state) => state.file.aboutPageData);
  return (
    <section className={styles.AboutUsStorySection}>
      <div className="container">
        <h2>{data?.[0]?.storyHeading}</h2>
        <div className={styles.storyWrapper}>
          <div className={styles.storyContent}>
            <Markdown>{data?.[0]?.storyParagraph}</Markdown>
          </div>
        </div>
        <div className={styles.imageRowWraop}>
          <figure>
            <img src={`${API_URI}${data?.[0]?.storyRowImage1?.url}`} alt="img" />
          </figure>
          <figure>
            <img src={`${API_URI}${data?.[0]?.storyRowImage2?.url}`} alt="img" />
          </figure>
          <figure>
            <img src={`${API_URI}${data?.[0]?.storyRowImage3?.url}`} alt="img" />
          </figure>
        </div>
      </div>
      <img
        src={Assets.aboutstoryyellow}
        className={styles.aboutstoryyellow}
        alt=""
      />
      <img
        src={Assets.aboutstorystar}
        className={styles.aboutstorystar}
        alt=""
      />
      <img
        src={Assets.aboutstoryvector}
        className={styles.aboutstoryvector}
        alt=""
      />
    </section>
  );
}
