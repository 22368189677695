import React from "react";
import styles from "./LandingSignup.module.scss"
import Assets from "../../../../layout/assets";
// import { useState } from "react";

export default function LandingSignup() {
  // const [addModalShow, setAddModalShow] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
    return(
        <section className={styles.LandingSignupSection}>
            <div className="container">
                <div className="row row-cols-md-2">
                  <div className={styles.signupButtonWrapper}>
                          <h2>Interested in owning your own preschool?</h2>
                          <button onClick={()=>scrollToTop()} className="btn btn-primary">APPLY NOW</button>
                  </div>
                  <div className={styles.imgWrapper}>
                    <figure>
                        <img src={Assets.mainImg} alt="main-img" />
                    </figure>
                  </div>
                </div>
            </div>
            {/* <LearnPopUp
                show={addModalShow}
                onHide={() => setAddModalShow(false)}
            />    */}
        </section>
    )
}