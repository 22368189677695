import React from "react";
import styles from "./HomePageTool.module.scss";
import {API_URI} from '../../../../helpers/constants';
import { useSelector } from "react-redux";

export default function HomePageTool(){
  const tool = useSelector((state) => state.file.homePageData);

    return(
        <section className={styles.HomePageToolSection}>
            <div className="container">
                <div className={`row ${styles.floatRow}`}>
                    <div className={`col-lg-3 col-sm-12 ${styles.ImageWrap}`}>
                        <figure>
                            <img src={`${API_URI}${tool?.[0]?.sectionThreeImage?.url}`} alt="folating-img"></img>
                        </figure>
                    </div>
                    <div className={`col-lg-8 col-sm-12 ${styles.TextWrap}`}>
                        <h2>{tool?.[0]?.sectionThreeHeading}</h2>
                        <p>{tool?.[0]?.sectionThreeDescription}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}