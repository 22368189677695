import React from "react";
import styles from "./HomePageOnboarding.module.scss";

export default function HomePageOnboarding({data}) {
    return(
        <section className={styles.HomePageOnboardingSection}>
            <div className="container">
                <h2>{data?.[0]?.title}</h2>
                <p>{data?.[0]?.description}</p>
                <div className={styles.procedureWrap}>
                    {data?.map((item,idx) => (
                        <div className={styles.procedure} key={item.id}>
                        <div className={styles.procedureHead}>
                            <p>{item.content}</p>
                        </div>
                        <div className={styles.procedureFooterBlue}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </section>
    )
}