import React from "react";
import styles from "./AboutUsInspire.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { API_URI } from "../../../helpers/constants";

export default function AboutUsInspire() {
  const data = useSelector((state) => state.file.aboutPageData);

  return (
    <section className={styles.AboutUsInspireSection}>
      <div className="container">
        <div className="row row-cols-md-2">
          <div className={styles.contentWrap}>
            <h2>{data?.[0]?.inspireHeading}</h2>
            <Link to="/contactus" className="btn btn-secondary">
              {data?.[0]?.inspireLearnMoreButton}
            </Link>
          </div>
          <div className={styles.ImgWrap}>
            <figure>
              <img
                src={`${API_URI}${data?.[0]?.inspireImage?.url}`}
                alt="main-img"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
