import React from "react";
import { API_URI } from "../../../helpers/constants";
import Assets from "../../../layout/assets";
import styles from "./AboutUsTopics.module.scss";

export default function AboutUsTopics({ data }) {
  return (
    <section className={styles.AboutUsTopicsWrapper}>
      <div className="container">
        <h2>{data?.[0]?.heading}</h2>
        <div className={styles.topicListWrapper}>
          {data?.map((topic) => (
            <div className={styles.TopicsWrapper} key={topic.id}>
              <div className={styles.TopicsCard}>
                <div className={styles.TopicsCardContent}>
                  <figure>
                    <img src={`${API_URI}${topic.image?.url}`}></img>
                  </figure>
                  <h4>{topic.title}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
