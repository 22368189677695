export const REDIRECTIONS = [
    {
        source:'/blogs/3',target:'/blogs'
    },
    {
        source:'/blogs/5',target:'/blogs'
    },
    {
        source:'/blogs/4',target:'/blogs'
    }
]