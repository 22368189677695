import React from "react";
import styles from "./LandingAbout.module.scss";
import Assets from "../../../../layout/assets";
export default function LandingAbout() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className={styles.LandingAboutWrap}>
      <div className="container">
        <div className={`row ${styles.customRow}`}>
          <div className={`col-md-4 col-sm-12 ${styles.imageWrap}`}>
            <figure>
              <img src={Assets.drawing} alt="img"></img>
            </figure>
          </div>
          <div className={`col-md-8 col-sm-12 ${styles.textWrap}`}>
            <h2>
              Even though up to 90% of our brain develops within the first five
              years of life, UNICEF reports that fewer than 22% of children in
              India have access to preschool education.
            </h2>
            <p>That’s where Kinderbridge comes in. </p>
            <p>
              We’ve developed an innovative, child-first curriculum which
              emphasizes creativity, social responsibility, and environmental
              care. And we’re providing a full year of that curriculum free of
              charge to five Indore-based edupreneurs.
            </p>
            {/* <PopupButton
                          id="HOQyhiU9"
                          className="btn btn-primary"
                          
                        >
                        LEARN MORE ABOUT THE KINDERBRIDGE PILOT PROGRAM
                        </PopupButton> */}

            {/* <Button
              id="HOQyhiU9"
              className="btn btn-primary"
              onClick={(e) => setAddModalShow(true)}
            >
              LEARN MORE ABOUT THE KINDERBRIDGE PILOT PROGRAM
            </Button> */}
            <button onClick={()=>scrollToTop()} className="btn btn-primary">LEARN MORE ABOUT THE KINDERBRIDGE PILOT PROGRAM</button>
            {/* <LearnPopUp
              show={addModalShow}
              onHide={() => setAddModalShow(false)}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
}
