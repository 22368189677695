import React from "react";
import styles from "./HomepagePartner.module.scss";
import { API_URI } from "../../../../helpers/constants";
import { useSelector } from "react-redux";

export default function HomepagePartner() {
  const partner = useSelector((state) => state.file.homePageData);
  return (
    <section className={styles.HomepagePartnerSection}>
      <div className="container">
        <div className="row">
          <div className={`col-md-8 col-sm-12 ${styles.TextWrap}`}>
            <h2>{partner?.[0]?.sectionFourHeading}</h2>
            <p>{partner?.[0]?.sectionFourDescription}</p>
            <div className={styles.tabWrap}>
              <figure>
                <img
                  src={`${API_URI}${partner?.[0]?.sectionFourChild1Image?.url}`}
                  alt="tab-img"
                ></img>
              </figure>
              <div className={styles.contentWrap}>
                <h3>{partner?.[0]?.sectionFourChild1Heading}</h3>
                <p>{partner?.[0]?.sectonFourChild1Description}</p>
              </div>
            </div>
            <div className={styles.tabWrap}>
              <figure>
                <img
                  src={`${API_URI}${partner?.[0]?.sectionFourChild2Image?.url}`}
                  alt="tab-img"
                ></img>
              </figure>
              <div className={styles.contentWrap}>
                <h3>{partner?.[0]?.sectionFourChild2Heading}</h3>
                <p>{partner?.[0]?.sectionFourChild2Description}</p>
              </div>
            </div>
            <div className={styles.tabWrap}>
              <figure>
                <img
                  src={`${API_URI}${partner?.[0]?.sectionFourChild3Image?.url}`}
                  alt="tab-img"
                ></img>
              </figure>
              <div className={styles.contentWrap}>
                <h3>{partner?.[0]?.sectionFourChild3Heading}</h3>
                <p>{partner?.[0]?.sectionFourChild3Description}</p>
              </div>
            </div>
          </div>
          <div className={`col-md-4 col-sm-12 ${styles.ImageWrap}`}>
            <figure>
              <img
                src={`${API_URI}${partner?.[0]?.sectionFourImage?.url}`}
                alt="main-img"
              ></img>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
