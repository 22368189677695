import React from "react";
import styles from "./LandingProcedures.module.scss";
import Assets from "../../../../layout/assets";


export default function LandingProcedures(){
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
    return(
        <section className={styles.LandingProceduresSection}>
            <div className="container">
                <figure>
                    <img src={Assets.waveimage} alt="img"></img>
                </figure>
                <h2>Getting Started with Kinderbridge</h2>
                <p>Interested in joining the Kinderbridge pilot program in Indore? Here’s how.</p>
                <div className={styles.procedureWrap}>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>GET IN TOUCH WITH US THROUGH OUR WEBSITE OR EMAIL OR CALL US</p>
                        </div>
                        <div className={styles.procedureFooterBlue}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>WE WILL CALL YOU BACK AND ASK YOU TO  COMPLETE THE APPLICATION FORM </p>
                        </div>
                        <div className={styles.procedureFooterOrange}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>WE WILL REVIEW YOUR  DETAILS AND CONTACT YOU WHEN YOUR APPLICATION IS APPROVED</p>
                        </div>
                        <div className={styles.procedureFooterBlue}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>WELCOME TO THE KINDER BRIDGE FAMILY!</p>
                        </div>
                        <div className={styles.procedureFooterOrange}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>CHOOSE YOUR LOCATION AND DECIDE ON THE AGE GROUPS YOU WILL BE WELCOMING TO YOUR CENTER</p>
                        </div>
                        <div className={styles.procedureFooterBlue}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>COMMENCE YOUR ACTIVITIESAND COMMUNICATE WITH PARENTS</p>
                        </div>
                        <div className={styles.procedureFooterBlue}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>OPEN YOUR DOORS! WELCOME CHILDREN FROM YOUR NEIGHBORHOOD</p>
                        </div>
                        <div className={styles.procedureFooterOrange}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>OUR LOCAL TEAM WILL  CONDUCT A PHYSICAL INSPECTION OF YOUR SPACE</p>
                        </div>
                        <div className={styles.procedureFooterBlue}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>DECIDE YOUR ACTIVTIES AND SET UP YOUR KINDER PASS PLATFORM</p>
                        </div>
                        <div className={styles.procedureFooterOrange}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.procedure}>
                        <div className={styles.procedureHead}>
                            <p>SET UP YOUR SCHOOL,FURNISH YOUR SPACE AND HIRE CAPABLE TEACHERS AND STAFF</p>
                        </div>
                        <div className={styles.procedureFooterBlue}>
                            <div className={styles.procedureFooterEnd}>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.signupButtonWrapper}>
                        {/* <PopupButton
                          id="HOQyhiU9"
                          className={`btn btn-primary ${styles.signupButton}`}
                        >
                        TAKE A SHORT QUIZ AND GET STARTED
                        </PopupButton> */}
                        <button onClick={()=>scrollToTop()} className="btn btn-primary">TAKE A SHORT QUIZ AND GET STARTED</button>
                </div>
            </div>
            <img src={Assets.pinkstar} alt="img" className={styles.pinkStar}></img>
        </section>
    )
}